/* ContactUs.css */

@font-face {
    font-family: myFirstFont;
    src: url(../style/fonts/Montserrat/static/Montserrat-Regular.ttf);
  } 
  
  
   @font-face {
    font-family: myFont;
    src: url(../style/fonts/Montserrat/static/Montserrat-Bold.ttf);
  } 
  
  
  @font-face {
    font-family: mySpanFont;
    src: url(../style/fonts/Montserrat/static/Montserrat-Bold.ttf);
  }

.contact-us {
    font-family:  myFirstFont;
  }
  
  .contact-container {
    display: flex;
  }
  
  .main-content {
    flex: 1;
    padding-left: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
  }
  
  /* .contact-us h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .contact-us p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .contact-form .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form .form-group label {
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  .contact-form .form-group input,
  .contact-form .form-group textarea {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form .form-group textarea {
    resize: vertical;
    height: 150px;
  }
  
  .contact-form button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact-form button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  
  .contact-form button:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
  .status-message {
    margin-top: 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #007bff;
  }
  
  .status-message.error {
    color: red;
  }
  
  .status-message.success {
    color: green;
  }
   */