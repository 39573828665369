.terms-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    max-width: 800px;
    margin: auto;
  }
  
  h1, h2 {
    color: #333;
  }
  
  p {
    margin: 10px 0;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Responsive styles */
  @media (max-width: 600px) {
    .terms-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 24px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    p {
      font-size: 16px;
    }
  }
  