.design-review-container {
    display: flex;
    justify-content: space-between;
    padding-top: 55px;
    gap: 20px;
    background-color: #fff !important;
  }
  
  .review-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .details {
    flex: 1;
    min-width: 400px;
    min-height: 80vh;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .details h3 {
    margin-bottom: 15px;
  }
  
  .details p {
    margin: 5px 0;
  }
  
  .details button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }
  
  .details button:hover {
    background-color: #0056b3;
  }
  
  .mockup-preview {
    flex: 1;
    min-width: 400px;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .mockup-preview h3 {
    margin-bottom: 15px;
  }
  
  .mockup-preview img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  /* Responsive adjustments */
  /* @media (max-width: 768px) {
    .design-review-container {
      flex-direction: column;
      padding: 10px;
    }
  
    .review-content {
      flex-direction: column;
      gap: 20px;
    }
  
    .details,
    .mockup-preview {
      min-width: 100%;
      margin-bottom: 20px;
    }
  }
   */