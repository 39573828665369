/* Product.css */
.products-page {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ensure the page takes the full height */
}

.products-container {
    display: flex;
    flex: 1;
    width: 100%;
    /* margin: 0 auto;
    padding: 20px; */
}

.main-content {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9; /* Light background for contrast */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

h2 {
    color: #333;
    margin-bottom: 20px;
}

.create-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4CAF50; /* Green background */
    color: white;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    margin-bottom: 20px;
    transition: background-color 0.3s;
}

.create-button:hover {
    background-color: #45a049; /* Darker green on hover */
}

.button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff; /* Green background */
    color: white;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    margin-bottom: 20px;
    transition: background-color 0.3s;
}

.design-table {
    width: 100%;
    border-collapse: collapse;
}

.design-table th,
.design-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.design-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.design-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.design-table tr:hover {
    background-color: #f5f5f5; /* Highlight row on hover */
}

.loading-message {
    text-align: center;
    color: #666;
    margin-top: 20px;
}


.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background-color: #000; /* Bootstrap primary color */
    color: white;
    border: none;
    padding: 10px 15px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .pagination button:hover {
    background-color: #808080; /* Darker shade for hover */
  }
  
  .pagination button.active {
    background-color: #808080; /* Highlight active page */
    font-weight: bold;
  }
  