.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.image-modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    max-width: 90%;
    max-height: 90%;
}

.large-image {
    width: 440px; /* Set desired width */
    height: 540px; /* Set desired height */
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
}


.imp{
 align-items: center;
}