/* .orders-container {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.orders-table th,
.orders-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.orders-table th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
}

.orders-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.orders-table tr:hover {
    background-color: #f1f1f1;
}

.confirm-button,
.cancel-button {
    padding: 10px 20px;
    font-size: 14px;
    margin: 5px;
    border: none;
    cursor: pointer;
}

.confirm-button {
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.confirm-button:hover {
    background-color: #45a049;
}

.cancel-button {
    background-color: #f44336;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.cancel-button:hover {
    background-color: #e53935;
} */


/* OrdersPage.css */
.orders-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.orders-container {
    display: flex;
    flex: 1;
    width: 100%;
    /* margin: 0 auto; */
    /* padding: 20px; */
}

.main-content {
    flex: 1;
    padding: 20px;
    background-color: #fff; /* Optional background color */
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.orders-table th,
.orders-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.orders-table th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
}

.orders-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.orders-table tr:hover {
    background-color: #f1f1f1;
}

/* Button styles */
.confirm-button,
.cancel-button {
    padding: 10px 20px;
    font-size: 14px;
    margin: 5px;
    border: none;
    cursor: pointer;
}

.confirm-button {
    background-color: #4CAF50 !important;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.confirm-button:hover {
    background-color: #45a049;
}

.cancel-button {
    background-color: #f44336;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.cancel-button:hover {
    background-color: #e53935;
}

.ordersp{
    text-align: center;
    font-weight: bold;
    
}

/* Modal styles */
.modals {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-contents {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-contents h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: #333;
}

.modal-contents p {
    font-size: 16px;
    color: #555;
    margin: 10px 0;
}

.modal-contents button {
    padding: 10px 20px;
    margin: 10px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-contents button.confirm-btn {
    background-color: #4CAF50;
    color: white;
}

.modal-contents button.confirm-btn:hover {
    background-color: #45a049;
}

.modal-contents button.cancel-btn {
    background-color: #f44336;
    color: white;
}

.modal-contents button.cancel-btn:hover {
    background-color: #e53935;
}


@media (max-width: 768px) {

    .hideee{
      display: none;
    }
}
