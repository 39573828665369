.sample-order {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure it appears above other elements */
}

.modal h2 {
    margin: 0;
    color: #fff;
}

.modal p {
    color: #fff;
    margin: 10px 0;
}

.modal button {
    background-color: #007bff; /* Bootstrap primary color */
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.modal button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.modal button:focus {
    outline: none; /* Remove default outline */
}

.modal-contents .cancel {
    background-color: #dc3545; /* Bootstrap danger color */
}

.modal  .cancel:hover {
    background-color: #c82333; /* Darker shade on hover */
}



h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

form {
    display: flex;
    flex-direction: column;
}

form div {
    margin-bottom: 15px;
}

label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

input[type="text"],
input[type="tel"] {
    height: 40px; /* Set consistent height */
}

button {
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .sample-order {
        padding: 15px;
    }

    h1 {
        font-size: 1.5rem;
    }

    input, select {
        font-size: 14px;
    }

    button {
        font-size: 14px;
    }
}

