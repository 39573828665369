/* 
  
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
}

.header-logo a {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.header-nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.header-nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.header-nav ul li a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  .header-nav ul {
    flex-direction: column;
    gap: 10px;
  }
  
  } */

  @font-face {
    font-family: myFirstFont;
    src: url(../style/fonts/Montserrat/static/Montserrat-Regular.ttf);
  } 
  
  
   @font-face {
    font-family: myFont;
    src: url(../style/fonts/Montserrat/static/Montserrat-Bold.ttf);
  } 
  
  
  @font-face {
    font-family: mySpanFont;
    src: url(../style/fonts/Montserrat/static/Montserrat-Bold.ttf);
  }
  

  
  .header {
    background-color: #141414;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    position: sticky;
    top: 0; /* Add this to ensure it sticks to the top */
    z-index: 1000; /* High z-index to stay above other elements */
    font-family: myFirstFont;
  }

  /* .logo {
    display: flex;
    align-items: center;
  }
  .logo img {
    height: 20px;
    margin-right: 1000px;
   } */

   /*
  .menu-icon {
    display: none;
    cursor: pointer;
  }
  .menu-icon .bar {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
  }
  .balance-info {
    display: flex;
    align-items: center;
  }
  .balance-info span {
    margin-right: 20px;
  }
  .recharge-btn,
  .logout-btn {
    margin-right: 10px;
    font-family: myFirstFont;
  } */
  /* Mobile */
  /* @media screen and (max-width: 768px) {
    .menu-icon {
      display: block;
    }
    .balance-info {
      display: none;
    }
  } */
  /* Modal backdrop and content */
  .modal-backdrop {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
  }
  .modal-content {
    background-color: black;
    border-radius: 8px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 600px;
    padding: 2rem;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
  }
  
  .modal-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
  }
  
  .wallet-amount {
    margin-bottom: 1rem;
    font-weight: bold;
    color: white;
  }
  
  /* Amount input */
  .amount-input {
    margin-bottom: 1rem;
    color: white;
  }
  
  .input-field {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    color: black;
    border-radius: 4px;
  }
  .input-hint {
    font-size: 0.875rem;
    color: #666;
  }
  /* Quick select buttons */
  .quick-select {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1rem;
    color: white;
  }
  
  .quick-btn {
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    background-color: black;
    cursor: pointer;
    border-radius: 4px;
    color: white;
  }
  
  .quick-btn.selected {
    background-color: #3399cc;
    color: white;
  }
  /* Payment summary */
  .payment-summary {
    margin-bottom: 1rem;
    color: white;
  }
  
  .summary-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
  }
  .payable {
    font-weight: bold;
  }
  /* Payment button */
  .payment-btn {
    width: 100%;
    padding: 0.75rem;
    background-color: #3399cc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .payment-btn:hover {
    background-color: #297aa8;
  }
  /* Header styles */
  .header {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: black;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    position: sticky;
  }
  .logo img {
    height: 40px;
  }
  .menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 18px;
    cursor: pointer;
  }
  .bar {
    width: 100%;
    height: 2px;
    background-color: #333;
  }
  .balance-info {
    display: flex;
    align-items: center;
  }
  .wallet-section {
    display: flex;
    align-items: center;
  }
  .wallet-icon,
  .refresh-icon {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
  .wallet-balance {
    font-weight: bold;
  }
  .recharge-btn {
    padding: 0.5rem 1rem;
    background-color: #3399cc;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 1rem;
  }


  @media (max-width: 768px) 

{
  .logo img {
    height: 40px;
    margin-right: 0;
   }

}