/* TransactionsPage.css */
.transactions-page {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Ensure the page takes the full height */
}

.transactions-container {
    display: flex;
    flex: 1;
    width: 100%;
    /* margin: 0 auto;
    padding: 20px; */
}

.main-content {
    flex: 1;
    padding: 20px;
    background-color: #fff; /* Optional background color */
}

.page-title {
    color: #333;
    text-align: center;
}

.error-message {
    color: #d32f2f;
    text-align: center;
}

.loading-message {
    text-align: center;
    color: #666;
}

.analytics-section {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.analytics-card {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    flex: 1;
    margin: 0 10px;
}

.analytics-card h3 {
    margin-top: 0;
    color: #333;
}

.analytics-card p {
    font-size: 1.2em;
    font-weight: bold;
    color: #4caf50;
}

.transactions-table {
    width: 100%;
    border-collapse: collapse;
}

.transactions-table th,
.transactions-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

.transactions-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.transactions-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.transactions-table tr:hover {
    background-color: #f5f5f5;
}
