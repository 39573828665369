
@font-face {
  font-family: myFirstFont;
  src: url(../style/fonts/Montserrat/static/Montserrat-Regular.ttf);
}

@font-face {
  font-family: myFont;
  src: url(../style/fonts/Montserrat/static/Montserrat-Bold.ttf);
}

@font-face {
  font-family: mySpanFont;
  src: url(../style/fonts/Montserrat/static/Montserrat-Bold.ttf);
}




.dashboard {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  font-family: myFirstFont;
  background-color: #f7f7f7; 
}

.dashboard-container {
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden; 
}


.main-content {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto; 
}


.card-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px;
  margin-bottom: 30px;
  justify-content: space-between;
}

.card {
  flex: 1;
  min-width: 250px; 
  padding: 20px;
  background-color: #000;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(210, 146, 146, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card p {
  text-decoration: none;
  color: white;
  font-size: 16px;
}


.chart-container {
  display: flex;
  flex-wrap: wrap; 
  gap: 20px;
  justify-content: space-between;
}

/* Container for the map and overlay */
.map-container {
  position: relative;
}

/* Map styles with blur effect */
.map-container .pigeon-map {
  filter: blur(5px); /* Apply blur to the map */
  transition: filter 0.3s ease; /* Smooth transition for blur effect */
  width: 100%; /* Ensure the map covers the container width */
  height: 100%;
}

/* Overlay for "Coming Soon" message */
.coming-soon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  padding: 20px;
  font-weight: bold;
  text-align: center;
  z-index: 10; 
  pointer-events: none; 
  white-space: nowrap; /* Prevent the text from wrapping onto multiple lines */
  overflow: hidden; /* Ensure that any overflowed text doesn't spill outside the container */
  text-overflow: ellipsis; /* Add an ellipsis if the text overflows (optional) */
}


/* Optional: Remove blur effect when hovering over the map */
.map-container .pigeon-map:hover {
  filter: blur(0); /* Remove blur effect on hover */
}


.chart {
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease;
}

.chart h3 {
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px;
}



.chart canvas {
  max-width: 100% !important;
  max-height: 350px !important; 
  padding: 0;
  margin: 0;
}




.chart .popup {
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.d1 {
  font-weight: bold;
  font-family: myFont;
}


.date-picker-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.date-picker-container .react-datepicker-wrapper {
  width: 100%;
  max-width: 200px; 
}

.react-datepicker__input-container input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 100%;
}


@media (max-width: 1200px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .chart-container {
    flex-direction: column;
  }

  .chart {
    width: 100%;
    margin-bottom: 20px;
  }

  .map {
    width: 100%;
  }

  .main-content {
    padding: 10px;
  }
}

@media (max-width: 768px) 



{
  .chart-container {
    gap: 10px;
  }

  .card-container {
    gap: 15px;
  }

  .card {
    flex: 1;
    min-width: 100%;
  }

  .chart {
    min-width: 100%;
  }

  .dashboard {
    /* display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: myFirstFont;
    background-color: #f7f7f7;  */
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .chart-container {
    flex-direction: column;
  }

  .card {
    width: 100%;
    margin-bottom: 15px;
  }

  .chart {
    margin-bottom: 20px;
  }
}
