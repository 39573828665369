
.sidebar {
  background-color: #000;
  color: #fff;
  width: 250px;
  /* height: 100vh; */
  position: relative; 
  z-index: 1000;
  transition: transform 0.3s ease;
}


.sidebar.closed {
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}


.hamburger-menu {
  position: fixed;
  top: 15px;
  left: 15px;
  background: #000;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1100;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #111;
}

.sidebar-header .logo img {
  height: 40px;
}

.sidebar-header .close-icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: #fff;
}

.menu {
  margin-top: 20px;
}

.menu-item {
  color: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.menu-item:hover {
  background: #333;
}

.icon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.menu-item span {
  font-size: 1rem;
}

.sidebar .menu-item a {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
}

/* .founder-info {
  padding: 10px;
  background: #333;
  margin-top: 5px;
  border-radius: 5px;
} */


/* Mobile Specific */
@media (max-width: 768px) {
  .sidebar {
    width: 250px;
    position: fixed; 
  }

  .sidebar.closed {
    transform: translateX(-100%);
  }

  .menu-item span {
    display: inline;
  }
}
