
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
 
  .placeorder-page {
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
    padding: 0px;
  }
  
  
  h1, h2, h3 {
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  /* .product-selection-modal{
    background-color: #333;
    width: 100%;
    max-width: 1000px; 
    margin: 20px auto;
  } */
  
  .product-selection-modal{
    background-color: #fff;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
    max-height: 80vh;  /* Limit the height to 80% of the viewport height */
    overflow-y: auto;  /* Enable vertical scrolling if content overflows */
}

.sample-order-product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
}

.product-item {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s;
}

 
  .product-selection {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  
  .product-selection button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .product-selection button:hover {
    background-color: #0056b3;
  }
  

  
  /* .sample-order-product-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
  }
  
  
  .product-item {
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s;
  } */

  
  .product-item:hover {
    transform: scale(1.05);
  }
  
  .product-item img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .product-item div {
    margin-top: 10px;
  }
  
  .product-item h3 {
    font-size: 18px;
    color: #333;
  }
  
  .product-item p {
    font-size: 14px;
    color: #666;
  }
  

  select {
    margin: 10px 0;
    padding: 8px;
    font-size: 14px;
    border-radius: 5px;
    width: 100%;
    max-width: 200px;
    border: 1px solid #ccc;
  }
  

  .product-details {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
  
  .product-details img {
    max-width: 150px;
    margin: 10px;
    border-radius: 8px;
  }
  

  .total-price {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
  }
  
 
  .shipping-form, .review-order {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .shipping-form label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  }
  
  .shipping-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  

  .submit-order-button,
  .cancel-button,
  .confirm-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-order-button:hover,
  .confirm-button:hover {
    background-color: #0056b3;
  }
  
  .cancel-button {
    background-color: #f44336;
  }
  
  .cancel-button:hover {
    background-color: #d32f2f;
  }
  
  
  .confirmation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;  
    max-width: 90%; 
    z-index: 10000; 
  }
  
  .close-button {
    background-color: #f44336;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .close-button:hover {
    background-color: #d32f2f;
  }
  

  .Toastify__toast-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
  }
  

  .payment-method-options {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .payment-method-options label {
    margin-right: 20px;
    font-size: 16px;
  }
  
  .payment-method-options input {
    margin-right: 5px;
  }
  
  

  @media (max-width: 768px) {
    .product-selection {
      padding: 15px;
    }
  
    .product-list {
      grid-template-columns: 1fr;
    }
  
    .product-item {
      padding: 10px;
    }
  
    .product-details {
      padding: 15px;
    }
  
    .total-price {
      font-size: 16px;
    }
  
    .shipping-form, .review-order {
      padding: 15px;
    }
  
    .shipping-form input {
      padding: 8px;
    }
  
    .submit-order-button,
    .cancel-button,
    .confirm-button {
      width: 100%;
      padding: 12px;
      font-size: 16px;
    }
  }
    
    @media (max-width: 480px) {
      .product-item h3 {
        font-size: 16px;
      }
    
      .product-item p {
        font-size: 12px;
      }
    
      .product-details img {
        max-width: 100px;
      }
    
      .submit-order-button,
      .cancel-button,
      .confirm-button {
        font-size: 14px;
        padding: 10px;
      }
    }
    