/* Style for the select-container */
.select-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
  
  .select-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  /* Style for the button container */
  .option-button-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  
  /* Wrapper for each button and its text */
  .option-button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* Style for the buttons */
  .option-button {
    width: 300px; /* Make the buttons large */
    height: 300px;
    background-size: cover; /* Make sure the image fits inside */
    background-position: center;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    overflow: hidden; /* Make sure no image overflows the button */
    transition: transform 0.3s ease;
  }
  
  .option-button:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  /* Text below the button */
  .button-text {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #333; /* Text color */
    text-align: center;
  }
  

  .option-button .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
  }