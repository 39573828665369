/* .privacy-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    max-width: 800px;
    margin: auto;
  }
  
  h1, h2 {
    color: #333;
  }
  
  p {
    margin: 10px 0;
  }
  
  ul {
    margin: 10px 0 10px 20px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  

  @media (max-width: 600px) {
    .privacy-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 24px;
    }
  
    h2 {
      font-size: 20px;
    }
  
    p, ul {
      font-size: 16px;
    }
  }
   */


   /* PrivacyPolicy.css */

.privacy-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.privacy-container {
  display: flex;
  flex: 1;
  height: 130vh;
}


.main-content {
  flex: 1;
  padding: 20px;
  background-color: #fff;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5rem;
  margin-top: 20px;
}

p {
  margin: 10px 0;
}

ul {
  margin: 10px 0 20px 20px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

strong {
  font-weight: bold;
}
